export const CREATE_PICKUP_REQUEST = "CREATE_PICKUP_REQUEST"
export const CREATE_PICKUP_SUCCESS = "CREATE_PICKUP_SUCCESS"
export const CREATE_PICKUP_FAILURE = "CREATE_PICKUP_FAILURE"
export const READ_PICKUP_REQUEST = "READ_PICKUP_REQUEST"
export const READ_PICKUP_SUCCESS = "READ_PICKUP_SUCCESS"
export const READ_PICKUP_FAILURE = "READ_PICKUP_FAILURE"
export const UPDATE_PICKUP_REQUEST = "UPDATE_PICKUP_REQUEST"
export const UPDATE_PICKUP_SUCCESS = "UPDATE_PICKUP_SUCCESS"
export const UPDATE_PICKUP_FAILURE = "UPDATE_PICKUP_FAILURE"


export const CREATE_PICKUP_JOB_REQUEST = "CREATE_PICKUP_JOB_REQUEST"
export const CREATE_PICKUP_JOB_SUCCESS = "CREATE_PICKUP_JOB_SUCCESS"
export const CREATE_PICKUP_JOB_FAILURE = "CREATE_PICKUP_JOB_FAILURE"
export const READ_PICKUP_JOB_REQUEST = "READ_PICKUP_JOB_REQUEST"
export const READ_PICKUP_JOB_SUCCESS = "READ_PICKUP_JOB_SUCCESS"
export const READ_PICKUP_JOB_FAILURE = "READ_PICKUP_JOB_FAILURE"
export const UPDATE_PICKUP_JOB_REQUEST = "UPDATE_PICKUP_JOB_REQUEST"
export const UPDATE_PICKUP_JOB_SUCCESS = "UPDATE_PICKUP_JOB_SUCCESS"
export const UPDATE_PICKUP_JOB_FAILURE = "UPDATE_PICKUP_JOB_FAILURE"