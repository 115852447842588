import { createStore, applyMiddleware } from 'redux'
import rootReducer from "./RootReducer"
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { CONSTANTS } from "../../package.json"

const saveStoreToLS = state => {
    try {
        const stringified = JSON.stringify({
            user: {
                currentUser: state.user.currentUser,
                loggingIn: state.user.loggingIn,
                loggedIn: state.user.loggedIn,
                loginError: state.user.loginError,
                jwt_key: state.user.jwt_key,
                allUsers: [],
                normalizedUsers: [],
                userNotes: {
                    notes: [],
                    createStatus: "idle",
                    createMessage: "",
                    readStatus: "idle",
                    readMessage: ""
                },
                resetPassword: {
                    emailStatus: "idle",
                    emailMessage: "",

                    verifyStatus: "idle",
                    verifyMessage: "",

                    resetStatus: "idle",
                    resetMessage: "",
                    userEmail: ""
                }
            },
            tempCart: state.tempCart
        })
        localStorage.setItem("state_US_"+CONSTANTS.key, stringified)
    } catch (e) {
        console.error(e)
    }
}

const loadStoreFromLS = () => {
    try {
        const stringified = localStorage.getItem("state_US_"+CONSTANTS.key)
        if (stringified == null) return undefined
        else {
            const state = JSON.parse(stringified)
            return {
                user: state.user,
                tempCart: state.tempCart,
            }
        }
    } catch (e) {
        console.error(e)
    }
}

const store = createStore(
    rootReducer,
    loadStoreFromLS(),
    composeWithDevTools(applyMiddleware(thunk))
)

store.subscribe(() => saveStoreToLS(store.getState()))

export default store


