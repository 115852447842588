export const CREATE_CONVERSATION_REQUEST = "CREATE_CONVERSATION_REQUEST"
export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS"
export const CREATE_CONVERSATION_FAILURE = "CREATE_CONVERSATION_FAILURE"
export const READ_CONVERSATION_REQUEST = "READ_CONVERSATION_REQUEST"
export const READ_CONVERSATION_SUCCESS = "READ_CONVERSATION_SUCCESS"
export const READ_CONVERSATION_FAILURE = "READ_CONVERSATION_FAILURE"
export const UPDATE_CONVERSATION_REQUEST = "UPDATE_CONVERSATION_REQUEST"
export const UPDATE_CONVERSATION_SUCCESS = "UPDATE_CONVERSATION_SUCCESS"
export const UPDATE_CONVERSATION_FAILURE = "UPDATE_CONVERSATION_FAILURE"

export const CREATE_CONV_PART_REQUEST = "CREATE_CONV_PART_REQUEST"
export const CREATE_CONV_PART_SUCCESS = "CREATE_CONV_PART_SUCCESS"
export const CREATE_CONV_PART_FAILURE = "CREATE_CONV_PART_FAILURE"
export const READ_CONV_PART_REQUEST = "READ_CONV_PART_REQUEST"
export const READ_CONV_PART_SUCCESS = "READ_CONV_PART_SUCCESS"
export const READ_CONV_PART_FAILURE = "READ_CONV_PART_FAILURE"
export const UPDATE_CONV_PART_REQUEST = "UPDATE_CONV_PART_REQUEST"
export const UPDATE_CONV_PART_SUCCESS = "UPDATE_CONV_PART_SUCCESS"
export const UPDATE_CONV_PART_FAILURE = "UPDATE_CONV_PART_FAILURE"

export const SEE_MESSAGE_SUCCESS = "SEE_MESSAGE_SUCCESS"

export const CREATE_CONV_MESSAGE_REQUEST = "CREATE_CONV_MESSAGE_REQUEST"
export const CREATE_CONV_MESSAGE_SUCCESS = "CREATE_CONV_MESSAGE_SUCCESS"
export const CREATE_CONV_MESSAGE_FAILURE = "CREATE_CONV_MESSAGE_FAILURE"
export const READ_CONV_MESSAGE_REQUEST = "READ_CONV_MESSAGE_REQUEST"
export const READ_CONV_MESSAGE_SUCCESS = "READ_CONV_MESSAGE_SUCCESS"
export const READ_CONV_MESSAGE_FAILURE = "READ_CONV_MESSAGE_FAILURE"