export const CREATE_WORKER_REQUEST = "CREATE_WORKER_REQUEST"
export const CREATE_WORKER_SUCCESS = "CREATE_WORKER_SUCCESS"
export const CREATE_WORKER_FAILURE = "CREATE_WORKER_FAILURE"
export const READ_WORKER_REQUEST = "READ_WORKER_REQUEST"
export const READ_WORKER_SUCCESS = "READ_WORKER_SUCCESS"
export const READ_WORKER_FAILURE = "READ_WORKER_FAILURE"
export const UPDATE_WORKER_REQUEST = "UPDATE_WORKER_REQUEST"
export const UPDATE_WORKER_SUCCESS = "UPDATE_WORKER_SUCCESS"
export const UPDATE_WORKER_FAILURE = "UPDATE_WORKER_FAILURE"
export const DELETE_WORKER_REQUEST = "DELETE_WORKER_REQUEST"
export const DELETE_WORKER_SUCCESS = "DELETE_WORKER_SUCCESS"
export const DELETE_WORKER_FAILURE = "DELETE_WORKER_FAILURE"