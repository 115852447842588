import {
    CREATE_CONVERSATION_REQUEST,
    CREATE_CONVERSATION_SUCCESS,
    CREATE_CONVERSATION_FAILURE,
    READ_CONVERSATION_REQUEST,
    READ_CONVERSATION_SUCCESS,
    READ_CONVERSATION_FAILURE,
    UPDATE_CONVERSATION_REQUEST,
    UPDATE_CONVERSATION_SUCCESS,
    UPDATE_CONVERSATION_FAILURE,
    CREATE_CONV_PART_REQUEST,
    CREATE_CONV_PART_SUCCESS,
    CREATE_CONV_PART_FAILURE,
    READ_CONV_PART_REQUEST,
    READ_CONV_PART_SUCCESS,
    READ_CONV_PART_FAILURE,
    UPDATE_CONV_PART_REQUEST,
    UPDATE_CONV_PART_SUCCESS,
    UPDATE_CONV_PART_FAILURE,
    CREATE_CONV_MESSAGE_REQUEST,
    CREATE_CONV_MESSAGE_SUCCESS,
    CREATE_CONV_MESSAGE_FAILURE,
    READ_CONV_MESSAGE_REQUEST,
    READ_CONV_MESSAGE_SUCCESS,
    READ_CONV_MESSAGE_FAILURE,
    SEE_MESSAGE_SUCCESS,
} from "./constants"

const ChatReducer = {
    conversation: {
        conversations: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: "",
        updateStatus: "",
        updateMessage: ""
    },
    participant: {
        participants: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: "",
        updateStatus: "",
        updateMessage: ""
    },
    message: {
        messages: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: ""
    }
}

const Chat = (state = ChatReducer, action) => {
    let convs = []
    let parts = []
    let msgs = []
    let key = ""
    switch (action.type) {
        case CREATE_CONVERSATION_REQUEST:
            return { ...state, conversation: { ...state.conversation, createStatus: "pending", createMessage: "Creating conversation... Please wait." } }
        case CREATE_CONVERSATION_SUCCESS:
            convs = [...state.conversation.conversations]
            convs[action.payload.conversation._id] = action.payload.conversation
            return {
                ...state,
                conversation: { ...state.conversation, createStatus: "success", createMessage: "Conversation created successfully.", conversations: convs, new: action.payload.conversation }
            }
        case CREATE_CONVERSATION_FAILURE:
            return { ...state, conversation: { ...state.conversation, createStatus: "error", createMessage: action.payload?.message } }
        case READ_CONVERSATION_REQUEST:
            return { ...state, conversation: { ...state.conversation, readStatus: "pending", readMessage: "Loading conversations... Please wait." } }
        case READ_CONVERSATION_SUCCESS:
            if (action.payload?.conversation !== undefined) {
                convs = [...state.conversation.conversations]
                parts = [...state.participant.participants]
                msgs = [...state.message.messages]
                convs[action.payload.conversation._id] = action.payload.conversation
                parts[action.payload.conversation._id] = action.payload.participants
                msgs[action.payload.conversation._id] = action.payload.messages
                return {
                    ...state,
                    conversation: { ...state.conversation, readStatus: "success", readMessage: "Conversations loaded successfully.", conversations: convs },
                    participant: { ...state.participant, participants: parts },
                    message: { ...state.message, messages: msgs },
                }
            } else {
                return {
                    ...state,
                    conversation: { ...state.conversation, readStatus: "success", readMessage: "Conversations loaded successfully.", conversations: action.payload.conversations }
                }
            }
        case READ_CONVERSATION_FAILURE:
            return { ...state, conversation: { ...state.conversation, readStatus: "error", readMessage: action.payload?.message } }
        case UPDATE_CONVERSATION_REQUEST:
            return { ...state, conversation: { ...state.conversation, updateStatus: "pending", updateMessage: "Updating conversation... Please wait." } }
        case UPDATE_CONVERSATION_SUCCESS:
            convs = [...state.conversation.conversations]
            convs[action.payload.conversation._id] = action.payload.conversation
            return {
                ...state,
                conversation: { ...state.conversation, updateStatus: "success", updateMessage: "Conversation updated successfully.", conversations: convs }
            }
        case UPDATE_CONVERSATION_FAILURE:
            return { ...state, conversation: { ...state.conversation, updateStatus: "error", updateMessage: action.payload?.message } }
        case CREATE_CONV_PART_REQUEST:
            return { ...state, participant: { ...state.participant, createStatus: "pending", createMessage: "Creating participants... Please wait." } }
        case CREATE_CONV_PART_SUCCESS:
            key = Object.keys(action.payload.participants)[0]
            parts = [...state.participant.participants]
            parts[key] = action.payload.participants[key]
            return {
                ...state,
                participant: { ...state.participant, createStatus: "success", createMessage: "Participants added successfully.", participants: parts }
            }
        case CREATE_CONV_PART_FAILURE:
            return { ...state, participant: { ...state.participant, createStatus: "error", createMessage: action.payload?.message } }
        case READ_CONV_PART_REQUEST:
            return { ...state, participant: { ...state.participant, readStatus: "pending", readMessage: "Loading participants... Please wait." } }
        case READ_CONV_PART_SUCCESS:
            return {
                ...state,
                participant: { ...state.participant, readStatus: "success", readMessage: "Participants loaded successfully.", participants: action.payload.participants }
            }
        case READ_CONV_PART_FAILURE:
            return { ...state, participant: { ...state.participant, readStatus: "error", readMessage: action.payload?.message } }
        case UPDATE_CONV_PART_REQUEST:
            return { ...state, participant: { ...state.participant, updateStatus: "pending", updateMessage: "Updating participants list... Please wait." } }
        case UPDATE_CONV_PART_SUCCESS:
            key = Object.keys(action.payload.participants).find(k => action.payload.participants[k] !== null)
            parts = [...state.participant.participants]
            parts[key] = action.payload.participants[key]
            return {
                ...state,
                participant: { ...state.participant, updateStatus: "success", updateMessage: "Participants updated successfully.", participants: parts }
            }
        case UPDATE_CONV_PART_FAILURE:
            return { ...state, participant: { ...state.participant, updateStatus: "error", updateMessage: action.payload?.message } }
        case SEE_MESSAGE_SUCCESS:
            if (action.payload.participant === null) return state
            parts = [...state.participant.participants]
            if (parts[action.payload.participant._conversation_id] === undefined) parts[action.payload.participant._conversation_id] = [action.payload.participant]
            else parts[action.payload.participant._conversation_id] = [
                ...parts[action.payload.participant._conversation_id].filter(part => part._id !== action.payload.participant._id),
                action.payload.participant
            ]
            return {
                ...state,
                participant: { ...state.participant, participants: parts }
            }
        case CREATE_CONV_MESSAGE_REQUEST:
            return { ...state, message: { ...state.message, createStatus: "pending", createMessage: "Sending message... Please wait." } }
        case CREATE_CONV_MESSAGE_SUCCESS:
            msgs = [...state.message.messages]
            if (msgs[action.payload.message._conversation_id] === undefined) msgs[action.payload.message._conversation_id] = [action.payload.message]
            else msgs[action.payload.message._conversation_id].push(action.payload.message)
            return {
                ...state,
                message: { ...state.message, createStatus: "success", createMessage: "Message sent successfully.", messages: msgs }
            }
        case CREATE_CONV_MESSAGE_FAILURE:
            return { ...state, message: { ...state.message, createStatus: "error", createMessage: action.payload?.message } }
        case READ_CONV_MESSAGE_REQUEST:
            return { ...state, message: { ...state.message, readStatus: "pending", readMessage: "Loading messages... Please wait." } }
        case READ_CONV_MESSAGE_SUCCESS:
            return {
                ...state,
                message: { ...state.message, readStatus: "success", readMessage: "Messages loaded successfully.", message: action.payload.messages }
            }
        case READ_CONV_MESSAGE_FAILURE:
            return { ...state, message: { ...state.message, readStatus: "error", readMessage: action.payload?.message } }
        default:
            return { ...state };
    }
}

export default Chat