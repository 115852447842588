import { combineReducers } from 'redux'

import StocksReducer from "./Stocks/Reducer"
import TempCartReducer from "./Shop/TemporaryCart"
import JobsReducer from "./Jobs/Reducer"
import TableReducer from "./Tables/Reducer"
import TravelReducer from "./Travel/Reducer"
import TimeTracking from "./TimeTracking/Reducer"
import UserReducer from "./User/Reducer"
import GeneralReducer from "./General/Reducer"
import AssetsReducer from "./Assets/Reducer"
import ShopReducer from "./Shop/Reducer"
import StaffRequests from "./StaffRequests/Reducer"
import LeaveRequests from "./LeaveRequests/Reducer"
import CentralShop from "./CentralShop/Reducer"
import Documents from "./Documents/Reducer"
import VideosReducer from "./Videos/Reducer"
import PricesReducer from "./PricingModels/Reducer"
import WorkersReducer from "./BodyWorkers/Reducer"
import SettingsReducer from "./Settings/Reducer"
import PickupsReducer from "./Pickups/Reducer"
import ChatReducer from "./Chat/Reducer"
import WellM8Reducer from "./WellM8/Reducer"
import ReportScheduler from "./ReportScheduler/Reducer"

const appReducer = combineReducers(
    {
        assets: AssetsReducer,
        centralShop: CentralShop,
        chat: ChatReducer,
        documents: Documents,
        general: GeneralReducer,
        job: JobsReducer,
        leaves: LeaveRequests,
        settings: SettingsReducer,
        pickups: PickupsReducer,
        prices: PricesReducer,
        schedules: ReportScheduler,
        shop: ShopReducer,
        staff: StaffRequests,
        stocks: StocksReducer,
        tables: TableReducer,
        tempCart: TempCartReducer,
        time: TimeTracking,
        travel: TravelReducer,
        user: UserReducer,
        videos: VideosReducer,
        wellmate: WellM8Reducer,
        workers: WorkersReducer,
    }
)

export default appReducer