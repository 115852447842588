import {
    READ_WORKER_REQUEST,
    READ_WORKER_SUCCESS,
    READ_WORKER_FAILURE,
    CREATE_WORKER_REQUEST,
    CREATE_WORKER_SUCCESS,
    CREATE_WORKER_FAILURE,
    UPDATE_WORKER_REQUEST,
    UPDATE_WORKER_SUCCESS,
    UPDATE_WORKER_FAILURE,
    DELETE_WORKER_REQUEST,
    DELETE_WORKER_SUCCESS,
    DELETE_WORKER_FAILURE,
} from "./constants"


const workers = {
    worker: {
        workers: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: "",
        updateStatus: "",
        updateMessage: "",
        deleteStatus: "",
        deleteMessage: "",
    },
}

const WorkersReducer = (state = workers, action) => {
    let work = []
    switch (action.type) {
        case READ_WORKER_REQUEST:
            return {
                ...state,
                worker: {
                    ...state.worker,
                    readStatus: "pending",
                    readMessage: "Loading workers... Please wait",
                }
            }
        case READ_WORKER_SUCCESS:
            work = []
            for (const worker of action.payload.workers) work[worker._id] = worker
            return {
                ...state,
                worker: {
                    ...state.worker,
                    readStatus: "success",
                    readMessage: "workers loaded.",
                    workers: work
                }
            }
        case READ_WORKER_FAILURE:
            return {
                ...state,
                worker: {
                    ...state.worker,
                    readStatus: "error",
                    readMessage: action.payload?.message
                }
            }
        case CREATE_WORKER_REQUEST:
            return {
                ...state,
                worker: {
                    ...state.worker,
                    createStatus: "pending",
                    createMessage: "Creating worker... Please wait",
                }
            }
        case CREATE_WORKER_SUCCESS:
            work = [...state.worker.workers]
            work[action.payload.worker._id] = action.payload.worker
            return {
                ...state,
                worker: {
                    ...state.worker,
                    createStatus: "success",
                    createMessage: "worker created.",
                    workers: work
                }
            }
        case CREATE_WORKER_FAILURE:
            return {
                ...state,
                worker: {
                    ...state.worker,
                    createStatus: "error",
                    createMessage: action.payload?.message
                }
            }
        case UPDATE_WORKER_REQUEST:
            return {
                ...state,
                worker: {
                    ...state.worker,
                    updateStatus: "pending",
                    updateMessage: "Updating worker... Please wait",
                }
            }
        case UPDATE_WORKER_SUCCESS:
            work = [...state.worker.workers]
            work[action.payload.worker._id] = action.payload.worker
            return {
                ...state,
                worker: {
                    ...state.worker,
                    updateStatus: "success",
                    updateMessage: "Worker updated.",
                    workers: work
                }
            }
        case UPDATE_WORKER_FAILURE:
            return {
                ...state,
                worker: {
                    ...state.worker,
                    updateStatus: "error",
                    updateMessage: action.payload?.message
                }
            }
        case DELETE_WORKER_REQUEST:
            return {
                ...state,
                worker: {
                    ...state.worker,
                    deleteStatus: "pending",
                    deleteMessage: "Deleting worker... Please wait.",
                }
            }
        case DELETE_WORKER_SUCCESS:
            work = [...state.worker.workers]
            work[action.payload.worker._id] = action.payload.worker
            return {
                ...state,
                worker: {
                    ...state.worker,
                    deleteStatus: "success",
                    deleteMessage: action.payload.worker._archived === 1 ? "Worker Deleted" : "Worker Retrived",
                    workers: work
                }
            }
        case DELETE_WORKER_FAILURE:
            return {
                ...state,
                worker: {
                    ...state.worker,
                    deleteStatus: "error",
                    deleteMessage: action.payload?.message
                }
            }
        default:
            return { ...state };
    }
}

export default WorkersReducer