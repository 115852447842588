export const REGISTER_ORGANIZATION_REQUEST = "REGISTER_ORGANIZATION_REQUEST"
export const REGISTER_ORGANIZATION_SUCCESS = "REGISTER_ORGANIZATION_SUCCESS"
export const REGISTER_ORGANIZATION_FAIL = "REGISTER_ORGANIZATION_FAIL"

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST"
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS"
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL"

export const SET_MOOD_REQUEST = "SET_MOOD_REQUEST"
export const SET_MOOD_SUCCESS = "SET_MOOD_SUCCESS"
export const SET_MOOD_FAIL = "SET_MOOD_FAIL"