import {
    CREATE_REPORT_SCHEDULE_REQUEST,
    CREATE_REPORT_SCHEDULE_SUCCESS,
    CREATE_REPORT_SCHEDULE_FAILURE,
    READ_REPORT_SCHEDULE_REQUEST,
    READ_REPORT_SCHEDULE_SUCCESS,
    READ_REPORT_SCHEDULE_FAILURE,
    UPDATE_REPORT_SCHEDULE_REQUEST,
    UPDATE_REPORT_SCHEDULE_SUCCESS,
    UPDATE_REPORT_SCHEDULE_FAILURE,
    DELETE_REPORT_SCHEDULE_REQUEST,
    DELETE_REPORT_SCHEDULE_SUCCESS,
    DELETE_REPORT_SCHEDULE_FAILURE
} from "./constants"


const SchedulerReducer = {
    reportSchedule: {
        schedules: [],
        recipiants: [],
        filters: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: "",
        updateStatus: "",
        updateMessage: "",
        deleteStatus: "",
        deleteMessage: "",
    },
}

const ReportSchedulerReducer = (state = SchedulerReducer, action) => {
    let schedules = []
    let recipiants = []
    let filters = []
    switch (action.type) {
        case CREATE_REPORT_SCHEDULE_REQUEST:
            return { ...state, reportSchedule: { ...state.reportSchedule, createStatus: "pending", createMessage: "Creating Report Schedule... Please wait." } }
        case CREATE_REPORT_SCHEDULE_SUCCESS:
            schedules = [...state.reportSchedule.schedules]
            recipiants = [...state.reportSchedule.recipiants]
            filters = [...state.reportSchedule.filters]

            schedules[action.payload.report._id] = action.payload.report
            recipiants[action.payload.report._id] = action.payload.report_recipiants
            filters[action.payload.report._id] = action.payload.report_filters

            return {
                ...state,
                reportSchedule: {
                    ...state.reportSchedule, createStatus: "success", createMessage: "Report Schedule created successfully.",
                    schedules: schedules, recipiants: recipiants, filters: filters
                }
            }
        case CREATE_REPORT_SCHEDULE_FAILURE:
            return { ...state, reportSchedule: { ...state.reportSchedule, createStatus: "error", createMessage: action.payload?.message } }
        case READ_REPORT_SCHEDULE_REQUEST:
            return { ...state, reportSchedule: { ...state.reportSchedule, readStatus: "pending", readMessage: "Loading Report Schedules... Please wait." } }
        case READ_REPORT_SCHEDULE_SUCCESS:
            schedules = []
            recipiants = []
            filters = []

            for (const item of action.payload.reports) schedules[item._id] = item
            for (const item of action.payload.report_recipiants) recipiants[item._report_id] === undefined ? recipiants[item._report_id] = [item] : recipiants[item._report_id].push(item)
            for (const item of action.payload.report_filters) filters[item._report_id] === undefined ? filters[item._report_id] = [item] : filters[item._report_id].push(item)

            return {
                ...state,
                reportSchedule: {
                    ...state.reportSchedule, readStatus: "success", readMessage: "Report Schedules loaded successfully.",
                    schedules: schedules, recipiants: recipiants, filters: filters
                }
            }
        case READ_REPORT_SCHEDULE_FAILURE:
            return { ...state, reportSchedule: { ...state.reportSchedule, readStatus: "error", readMessage: action.payload?.message } }
        case UPDATE_REPORT_SCHEDULE_REQUEST:
            return { ...state, reportSchedule: { ...state.reportSchedule, updateStatus: "pending", updateMessage: "Updating Report Schedule... Please wait." } }
        case UPDATE_REPORT_SCHEDULE_SUCCESS:
            schedules = [...state.reportSchedule.schedules]
            recipiants = [...state.reportSchedule.recipiants]
            filters = [...state.reportSchedule.filters]

            schedules[action.payload.report._id] = action.payload.report
            recipiants[action.payload.report._id] = action.payload.report_recipiants
            filters[action.payload.report._id] = action.payload.report_filters

            return {
                ...state, reportSchedule: {
                    ...state.reportSchedule, updateStatus: "success", updateMessage: "Report Schedule updated successfully.",
                    schedules: schedules, recipiants: recipiants, filters: filters
                }
            }
        case UPDATE_REPORT_SCHEDULE_FAILURE:
            return { ...state, reportSchedule: { ...state.reportSchedule, updateStatus: "error", updateMessage: action.payload?.message } }
        case DELETE_REPORT_SCHEDULE_REQUEST:
            return { ...state, reportSchedule: { ...state.reportSchedule, deleteStatus: "pending", deleteMessage: "Deleting schedule... Please wait." } }
        case DELETE_REPORT_SCHEDULE_SUCCESS:
            schedules = [...state.reportSchedule.schedules]
            recipiants = [...state.reportSchedule.recipiants]
            filters = [...state.reportSchedule.filters]

            schedules[action.payload.report_id] = null
            recipiants[action.payload.report_id] = []
            recipiants[action.payload.report_id] = []
            return {
                ...state, reportSchedule: {
                    ...state.reportSchedule, deleteStatus: "success", deleteMessage: "Schedule has been deleted.",
                    schedules: schedules, recipiants: recipiants, filters: filters
                }
            }
        case DELETE_REPORT_SCHEDULE_FAILURE:
            return { ...state, reportSchedule: { ...state.reportSchedule, deleteStatus: "error", deleteMessage: action.payload?.message } }
        default:
            return { ...state };
    }
}

export default ReportSchedulerReducer