export const CREATE_REPORT_SCHEDULE_REQUEST = "CREATE_REPORT_SCHEDULE_REQUEST"
export const CREATE_REPORT_SCHEDULE_SUCCESS = "CREATE_REPORT_SCHEDULE_SUCCESS"
export const CREATE_REPORT_SCHEDULE_FAILURE = "CREATE_REPORT_SCHEDULE_FAILURE"
export const READ_REPORT_SCHEDULE_REQUEST = "READ_REPORT_SCHEDULE_REQUEST"
export const READ_REPORT_SCHEDULE_SUCCESS = "READ_REPORT_SCHEDULE_SUCCESS"
export const READ_REPORT_SCHEDULE_FAILURE = "READ_REPORT_SCHEDULE_FAILURE"
export const UPDATE_REPORT_SCHEDULE_REQUEST = "UPDATE_REPORT_SCHEDULE_REQUEST"
export const UPDATE_REPORT_SCHEDULE_SUCCESS = "UPDATE_REPORT_SCHEDULE_SUCCESS"
export const UPDATE_REPORT_SCHEDULE_FAILURE = "UPDATE_REPORT_SCHEDULE_FAILURE"
export const DELETE_REPORT_SCHEDULE_REQUEST = "DELETE_REPORT_SCHEDULE_REQUEST"
export const DELETE_REPORT_SCHEDULE_SUCCESS = "DELETE_REPORT_SCHEDULE_SUCCESS"
export const DELETE_REPORT_SCHEDULE_FAILURE = "DELETE_REPORT_SCHEDULE_FAILURE"