import {
    CREATE_PICKUP_REQUEST,
    CREATE_PICKUP_SUCCESS,
    CREATE_PICKUP_FAILURE,
    READ_PICKUP_REQUEST,
    READ_PICKUP_SUCCESS,
    READ_PICKUP_FAILURE,
    UPDATE_PICKUP_REQUEST,
    UPDATE_PICKUP_SUCCESS,
    UPDATE_PICKUP_FAILURE,
    CREATE_PICKUP_JOB_REQUEST,
    CREATE_PICKUP_JOB_SUCCESS,
    CREATE_PICKUP_JOB_FAILURE,
    READ_PICKUP_JOB_REQUEST,
    READ_PICKUP_JOB_SUCCESS,
    READ_PICKUP_JOB_FAILURE,
    UPDATE_PICKUP_JOB_REQUEST,
    UPDATE_PICKUP_JOB_SUCCESS,
    UPDATE_PICKUP_JOB_FAILURE
} from "./constants"

const pickups = {
    pickup: {
        pickups: [],
        jobs: [],
        statuses: [],
        createStatus: "",
        createMessage: "",
        readStatus: "",
        readMessage: "",
        updateStatus: "",
        updateMessage: ""
    },
}

const PickupsReducer = (state = pickups, action) => {
    let work = []
    let stats = []
    let jobs = []
    switch (action.type) {
        case READ_PICKUP_REQUEST:
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    readStatus: "pending",
                    readMessage: "Loading pickups... Please wait",
                }
            }
        case READ_PICKUP_SUCCESS:
            work = []
            stats = []
            jobs = []
            for (const pickup of action.payload.pickup_jobs) jobs[pickup._id] = pickup
            for (const pickup of action.payload.pickups) work[pickup._id] = pickup
            for (const stat of action.payload.statuses) stats[stat._id] = stat
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    readStatus: "success",
                    readMessage: "Pickups loaded.",
                    jobs: jobs,
                    pickups: work,
                    statuses: stats
                }
            }
        case READ_PICKUP_FAILURE:
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    readStatus: "error",
                    readMessage: action.payload?.message
                }
            }
        case CREATE_PICKUP_REQUEST:
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    createStatus: "pending",
                    createMessage: "Creating pickup... Please wait",
                }
            }
        case CREATE_PICKUP_SUCCESS:
            work = [...state.pickup.pickups]
            work[action.payload.pickup._id] = action.payload.pickup
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    createStatus: "success",
                    createMessage: "Pickup created.",
                    pickups: work
                }
            }
        case CREATE_PICKUP_FAILURE:
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    createStatus: "error",
                    createMessage: action.payload?.message
                }
            }
        case UPDATE_PICKUP_REQUEST:
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    updateStatus: "pending",
                    updateMessage: "Updating... Please wait",
                }
            }
        case UPDATE_PICKUP_SUCCESS:
            work = [...state.pickup.pickups]
            work[action.payload.pickup._id] = action.payload.pickup
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    updateStatus: "success",
                    updateMessage: "Pickup updated successfully.",
                    pickups: work
                }
            }
        case UPDATE_PICKUP_FAILURE:
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    updateStatus: "error",
                    updateMessage: action.payload?.message
                }
            }
        case READ_PICKUP_JOB_REQUEST:
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    readStatus: "pending",
                    readMessage: "Loading pickup jobs... Please wait",
                }
            }
        case READ_PICKUP_JOB_SUCCESS:
            jobs = []
            stats = []
            for (const pickup of action.payload.pickup_jobs) jobs[pickup._id] = pickup
            for (const stat of action.payload.statuses) stats[stat._id] = stat
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    readStatus: "success",
                    readMessage: "Pickup jobs loaded.",
                    jobs: jobs,
                    statuses: stats
                }
            }
        case READ_PICKUP_JOB_FAILURE:
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    readStatus: "error",
                    readMessage: action.payload?.message
                }
            }
        case CREATE_PICKUP_JOB_REQUEST:
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    createStatus: "pending",
                    createMessage: "Creating pickup job... Please wait",
                }
            }
        case CREATE_PICKUP_JOB_SUCCESS:
            jobs = [...state.pickup.pickups]
            jobs[action.payload.pickup._id] = action.payload.pickup
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    createStatus: "success",
                    createMessage: "Pickup job created.",
                    jobs: jobs
                }
            }
        case CREATE_PICKUP_JOB_FAILURE:
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    createStatus: "error",
                    createMessage: action.payload?.message
                }
            }
        case UPDATE_PICKUP_JOB_REQUEST:
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    updateStatus: "pending",
                    updateMessage: "Updating... Please wait",
                }
            }
        case UPDATE_PICKUP_JOB_SUCCESS:
            jobs = [...state.pickup.pickups]
            jobs[action.payload.pickup._id] = action.payload.pickup
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    updateStatus: "success",
                    updateMessage: "Pickup job updated successfully.",
                    jobs: jobs
                }
            }
        case UPDATE_PICKUP_JOB_FAILURE:
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    updateStatus: "error",
                    updateMessage: action.payload?.message
                }
            }
        default:
            return { ...state };
    }
}

export default PickupsReducer