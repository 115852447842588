import {
    REGISTER_ORGANIZATION_REQUEST,
    REGISTER_ORGANIZATION_SUCCESS,
    REGISTER_ORGANIZATION_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    SET_MOOD_REQUEST,
    SET_MOOD_SUCCESS,
    SET_MOOD_FAIL
} from "./constants"

const WellM8 = {
    organization: {
        organizations: [],
        createStatus: "undetermined",
        createMessage: "",
    },
    user: {
        createStatus: "undetermined",
        createMessage: "",
    },
    mood: {
        moods: [],
        createStatus: "undetermined",
        createMessage: "",
    },
}

const WellM8Reducer = (state = WellM8, action) => {
    switch (action.type) {
        case REGISTER_ORGANIZATION_REQUEST:
            return {
                ...state,
                organization: {
                    createStatus: "pending",
                    createMessage: "Creating organization... Please wait.",
                }
            }
        case REGISTER_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization: {
                    createStatus: "success",
                    createMessage: "Organization created successfully.",
                    organizations: state?.organization?.organizations === undefined ? [action.payload.organization] : [...state?.organization?.organizations, action.payload.organization]
                }
            }
        case REGISTER_ORGANIZATION_FAIL:
            return {
                ...state,
                organization: {
                    createStatus: "error",
                    createMessage: action.payload.message
                }
            }
        case REGISTER_USER_REQUEST:
            return {
                ...state,
                user: {
                    createStatus: "pending",
                    createMessage: "Registering new user... Please wait."
                }
            }
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                user: {
                    createStatus: "success",
                    createMessage: "New user registered."
                }
            }
        case REGISTER_USER_FAIL:
            return {
                ...state,
                user: {
                    createStatus: "error",
                    createMessage: action.payload.message
                }
            }
        case SET_MOOD_REQUEST:
            return {
                ...state,
                mood: {
                    createStatus: "pending",
                    createMessage: "Setting mood... Please wait."
                }
            }
        case SET_MOOD_SUCCESS:
            return {
                ...state,
                mood: {
                    createStatus: "success",
                    createMessage: "Mood has been set."
                }
            }
        case SET_MOOD_FAIL:
            return {
                ...state,
                mood: {
                    createStatus: "error",
                    createMessage: action.payload.message
                }
            }
        default:
            return { ...state };
    }
}

export default WellM8Reducer