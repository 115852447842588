import XLSX from 'sheetjs-style-v2'
import moment from 'moment-timezone'
import { STATIC_URL, TIMEZONE_REGION } from './redux/constants'

export const sum = arr => {
    let total = 0
    for (const i of arr) total += i
    return total
}

export const getDate = (date = new Date()) => {
    if (typeof date !== typeof new Date()) {
        date = new Date(date)
    }
    return date.getFullYear() + '-' + (parseInt(date.getMonth()) + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
}

export const formatDateString = date => {
    const parsed = new Date(Date.parse(date))

    const months_string = "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec"
    const months_array = months_string.split(" ")

    let output = ""

    output = output + parsed.getDate() < 10 ? "0" + parsed.getDate() : parsed.getDate()
    output = output + " " + months_array[parsed.getMonth()]
    output = output + " " + parsed.getFullYear()
    let hours = parsed.getHours() < 10 ? "0" + parsed.getHours() : parsed.getHours()
    output = output + " " + hours
    let minutes = parsed.getMinutes() < 10 ? "0" + parsed.getMinutes() : parsed.getMinutes()
    output = output + ":" + minutes


    return output
}

export const formatDateStringApple = date_string => {
    const months_string = "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec"
    const months_array = months_string.split(" ")

    const split_datetime = date_string.split(" ")
    const date = split_datetime[0].replace(",", "")
    const _split_time = split_datetime[1].split(" ")
    const time = _split_time[0]
    const isPm = _split_time[1] === "PM" ? true : false

    const split_date = date.split("/")
    const split_time = time.split(":")


    return `${split_date[1]} ${months_array[split_date[0] - 1]} ${split_date[2]} ${isPm ? split_time[0] + 12 : split_time[0] < 10 ? "0" + split_time[0] : split_time[0]}:${split_time[1]}`
}

export const getUtcString = (d = new Date()) => {
    // return moment().tz(TIMEZONE_REGION.value).format("YYYY-MM-DD HH:mm:ss")
    return moment.tz(moment(), TIMEZONE_REGION.value).format("YYYY-MM-DD HH:mm:ss")
}

export const getUtcdate = (d = new Date()) => {
    return new Date(moment().tz(TIMEZONE_REGION.value).format("MMM DD YYYY HH:mm:ss"))
}

export const getUtcStringIOS = (d = new Date()) => moment.tz(moment(), TIMEZONE_REGION.value).format("MMM DD YYYY HH:mm:ss")

export const getUtcdateIOS = (d = new Date()) => new Date(moment().tz(TIMEZONE_REGION.value).format("MMM DD YYYY HH:mm:ss"))

export const isDate = date => new Date(date) !== "Invalid Date" && isNaN(new Date(date)) === false;


export const getLocale = (date = getDate()) => {
    const syd_off = moment().tz(TIMEZONE_REGION.value)._offset
    const cli_off = new Date().getTimezoneOffset()
    const off = syd_off + cli_off
    const _date = new Date(date).getTime() - off * 60000

    return new Date(_date).toString().substring(4, 15) + " " + new Date(_date).toTimeString().substring(0, 5)
}

export const getLocaleFromUTC = (date = getDate()) => {
    const syd_off = moment().tz(TIMEZONE_REGION.value)._offset
    const cli_off = 0
    const off = syd_off + cli_off
    const _date = new Date(date).getTime() + off * 60000

    return new Date(_date).toString().substring(4, 15) + " " + new Date(_date).toTimeString().substring(0, 5)
}

export const getLocaleSpecial = date => {
    const syd_off = moment().tz(TIMEZONE_REGION.value)._offset
    const cli_off = new Date().getTimezoneOffset()
    const off = syd_off + cli_off
    const _date = new Date(date).getTime() - off * 60000

    return new Date(_date).toString().substring(4, 15) + " " + new Date(_date).toTimeString().substring(0, 5)
}

export const getLocaleDate = date => {
    const syd_off = moment().tz(TIMEZONE_REGION.value)._offset
    const cli_off = new Date().getTimezoneOffset()
    const off = syd_off + cli_off
    const _date = new Date(date).getTime() - off * 60000
    return new Date(_date)
}

export const compareInt = (number1, number2) => {
    return parseInt(number1) === parseInt(number2) ? true : false
}

export const compareType = (val1, val2) => {
    return typeof val1 === typeof val2 ? true : false
}

export const sortObjectArray = (objects, field) => {
    if (objects.length > 0 && isDate(objects[0][field]) === true) return sortObjectArray_d(objects, field)
    const sorter = (a, b) => {
        if (a[field] < b[field]) return -1;
        if (a[field] > b[field]) return 1;
        return 0;
    }

    return objects.sort(sorter)
}

export const sortObjectArray_d = (objects, field) => {
    const sorter = (a, b) => {
        if (new Date(a[field]) < new Date(b[field])) return -1;
        else return 1;
    }

    return objects.sort(sorter)
}

export const isIterable = obj => {
    if (obj == null) return false
    return typeof obj[Symbol.iterator] === 'function';
}

export const convertCalToDate = (cal, time) => {
    const year = cal.year
    const month = cal.month < 10 ? "0" + cal.month : cal.month
    const day = cal.day < 10 ? "0" + cal.day : cal.day

    return year + "-" + month + "-" + day + " " + time
}

export const convertDateToString = (date, time) => {
    const year = date.getFullYear()
    let month = parseInt(date.getMonth()) + 1
    let day = parseInt(date.getDate())

    if (month < 10) {
        month = "0" + parseInt(month)
    }
    if (day < 10) {
        day = "0" + parseInt(day)
    }

    return year + "-" + month + "-" + day + " " + time
}

export const getFilteredData = (data, fields) => {
    const _columns = data.cols.filter(column => fields.includes(parseInt(column.key)) === true)
    const _data = data.data.map((row, index) => {
        if (index === 0) return row
        else return row.filter((item, index) => fields.includes(index) === true)
    })
    return {
        cols: _columns,
        data: _data.filter(d => d.length > 0)
    }
}

export const generate = (exportData, fields) => {
    const _data = getFilteredData(exportData, fields)
    let wb = XLSX.utils.book_new()
    wb.Props = {
        Title: "Report",
        Subject: "Report",
        Author: "WorxManager",
        CreatedDate: new Date()
    }
    wb.SheetNames.push("Report")
    const ws_data = _data.data
    const ws = XLSX.utils.aoa_to_sheet(ws_data)
    const colRules = _data.data[1].map(text => ({ wch: 30 }))
    ws["!cols"] = colRules

    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split("")
    chars = [...chars, "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP"]

    for (const [idr, row] of ws_data.entries()) {
        for (const [idc] of row.entries()) {
            const field = chars[idc] + (idr + 1)?.toString()
            const isTotal = ws[field]?.v?.toString()?.includes("~") || ws[field]?.v?.toString()?.toLowerCase()?.includes("total")
            const isSubTotal = ws[field]?.v?.toString()?.toLowerCase()?.includes("subtotal")
            if (ws[field] !== undefined) ws[field].v = ws[field]?.v?.toString().includes("public/") ? STATIC_URL + ws[field]?.v?.toString() :  ws[field]?.v?.toString().replace("~", "")
            else ws[field] = { v: "" }
            if (isTotal) {
                for (const [cid] of row.entries()) {
                    const _field = chars[cid] + (idr + 1)?.toString()
                    ws[_field].s = {
                        fill: {
                            fgColor: { rgb: "FFAAAAAA" },
                            patternType: "solid"
                        },
                        font: {
                            bold: true,
                        }
                    }
                }
            }
            if (isSubTotal) {
                for (const [cid] of row.entries()) {
                    const _field = chars[cid] + (idr + 1)?.toString()
                    ws[_field].s = {
                        fill: {
                            fgColor: { rgb: "FFDDDDDD" },
                            patternType: "solid"
                        },
                    }
                }
            }
        }
    }

    wb.Sheets["Report"] = ws;
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
    return wbout
}

export const s2ab = s => {
    var buf = new ArrayBuffer(s.length)
    var view = new Uint8Array(buf)
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF
    return buf
}

export const generateRandom = length => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));
    return result;
}

export const getDistance = async (loc1, loc2) => {
    var origin = new window.google.maps.LatLng(loc1.lat, loc1.long)
    var destination = new window.google.maps.LatLng(loc2.lat, loc2.long)
    var service = new window.google.maps.DistanceMatrixService()

    service.getDistanceMatrix(
        {
            origins: [origin],
            destinations: [destination],
            travelMode: window.google.maps.TravelMode.DRIVING,
            unitSystem: window.google.maps.UnitSystem.METRIC,
            drivingOptions: {
                departureTime: new Date(Date.now() + 60000),
                trafficModel: 'optimistic'
            }
        },
        (res, status) => { }
    )
}

export const sortArrayOfArraysOfObjects = (aoa, index, shoudlAscend) => {
    aoa.sort((a, b) => {
        const name = Object.keys(b[index])[0]
        if (a[index][name] === parseInt(a[index][name]) && b[index][name] === parseInt(b[index][name])) {
            if (parseInt(a[index][name]) > parseInt(b[index][name])) return 1
            else return -1
        }
        else if (!isNaN(new Date(a[index][name])?.getTime()) && !isNaN(new Date(b[index][name])?.getTime())) {
            if (new Date(a[index][name]).getTime() > new Date(b[index][name]).getTime()) return 1
            else return -1
        }
        else {
            if (a[index][name]?.toString() === "") return 1
            else if (b[index][name]?.toString() === "") return -1
            else return a[index][name]?.toString().localeCompare(b[index][name].toString())
        }
    })

    if (shoudlAscend === true) return aoa
    else return aoa.reverse()
}

export const calculateJobRepairTime = (job, job_pauses, _get_default = false) => {
    if (job._finish_date === null && _get_default === false) return 0
    let repiar_time
    if (_get_default === false) {
        repiar_time = new Date(job._finish_date) - new Date(job._start_date)
    }
    else {
        repiar_time = getUtcdate() - new Date(job._start_date)
        if (job._status_id === 12) return Math.floor((getUtcdate() - new Date(job._redo_start)) / 60000)
    }
    let pause_time = 0

    // if (job._redo_finish !== null) repiar_time = repiar_time + new Date(job._redo_finish) - new Date(job._redo_start)

    if (job_pauses !== undefined) {
        for (let pause of job_pauses) {

            if (pause._pause_end === null || compareInt(job._id, pause._job_id) === false)
                continue

            const from = new Date(pause._pause_start)
            const to = new Date(pause._pause_end)
            // if (from > new Date(job._start_date) && to < new Date(job._finish_date))
            pause_time = pause_time + (to.getTime() - from.getTime())
        }
    }


    repiar_time = (repiar_time - pause_time) / 60000

    return parseInt(repiar_time)

}

export const calculateJobActiveTime = (job, pauses) => {
    if (job._finish_date !== null) {
        let repiar_time = new Date(job._finish_date) - new Date(job._start_date)

        for (const p of pauses) {
            if (p._pause_end !== null && p._job_id === job._id && new Date(job._pause_end) < new Date(job._finish_date) && new Date(job._pause_start) > new Date(job._start_date)) repiar_time -= new Date(job._pause_end) - new Date(job._pause_start)
        }
        return parseInt(repiar_time / 60000)
    } else {
        let pause_time = 0
        let last_pause = null
        for (const p of pauses) {
            if (p._pause_end !== null && p._job_id === job._id) pause_time += new Date(job._pause_end) - new Date(job._pause_start)
            if (p._pause_end === null) last_pause = p
        }
        if (last_pause !== null) return parseInt((new Date(last_pause._pause_end) - new Date(job._start_date)) - pause_time / 60000)
    }
}

export const download = (filename, text) => {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export const getDateFromRange = dates => {
    try {
        if (dates?.from && dates?.to)
            return dates.from.toDateString() + " to " + dates.to.toDateString()
        else return dates.toDateString()
    } catch (error) {
        return "Invalid Date(s)"
    }
}

export const diff_hours = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000
    diff /= (60 * 60)
    return Math.round(diff) * -1
}

export const diff_mins = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000
    diff /= (60)
    return Math.round(diff) * -1
}


export const randomColor = () => {
    let color = '#';
    for (let i = 0; i < 6; i++) {
        const random = Math.random();
        const bit = (random * 16) | 0;
        color += (bit).toString(16);
    };
    return color;
};