import { compareInt } from "../../helpers"
import {
    UPDATE_CANCEL_REQUEST,
    UPDATE_CANCEL_SUCCESS,
    UPDATE_CANCEL_FAIL,
    CREATE_JOB_REQUEST,
    CREATE_JOB_SUCCESS,
    CREATE_JOB_FAIL,
    READ_JOB_REQUEST,
    READ_JOB_SUCCESS,
    READ_JOB_FAIL,
    GET_JOB_REQUEST,
    GET_JOB_SUCCESS,
    GET_JOB_FAIL,
    UPDATE_JOB_REQUEST,
    UPDATE_JOB_SUCCESS,
    UPDATE_JOB_FAIL,
    CREATE_OTHER_REQUEST,
    READ_JOB_HIST_REQUEST,
    READ_JOB_HIST_SUCCESS,
    READ_JOB_HIST_FAIL,
    CREATE_OTHER_SUCCESS,
    CREATE_OTHER_FAIL,
    READ_OTHER_REQUEST,
    READ_OTHER_SUCCESS,
    READ_OTHER_FAIL,
    UPDATE_OTHER_REQUEST,
    UPDATE_OTHER_SUCCESS,
    UPDATE_OTHER_FAIL,
    CREATE_PAUSE_REQUEST,
    CREATE_PAUSE_SUCCESS,
    CREATE_PAUSE_FAIL,
    READ_PAUSE_REQUEST,
    READ_PAUSE_SUCCESS,
    READ_PAUSE_FAIL,
    UPDATE_PAUSE_REQUEST,
    UPDATE_PAUSE_SUCCESS,
    UPDATE_PAUSE_FAIL,
    CREATE_IMAGE_REQUEST,
    CREATE_IMAGE_SUCCESS,
    CREATE_IMAGE_FAIL,
    READ_IMAGE_REQUEST,
    READ_IMAGE_SUCCESS,
    READ_IMAGE_FAIL,
    CREATE_COMMENT_REQUEST,
    CREATE_COMMENT_SUCCESS,
    CREATE_COMMENT_FAIL,
    READ_COMMENT_REQUEST,
    READ_COMMENT_SUCCESS,
    READ_COMMENT_FAIL,
    CREATE_CANCEL_REQUEST,
    CREATE_CANCEL_SUCCESS,
    CREATE_CANCEL_FAIL,
    READ_CANCEL_REQUEST,
    READ_CANCEL_SUCCESS,
    READ_CANCEL_FAIL,
    CREATE_DECLINE_REQUEST,
    CREATE_DECLINE_SUCCESS,
    CREATE_DECLINE_FAIL,
    UPDATE_DECLINE_REQUEST,
    UPDATE_DECLINE_SUCCESS,
    UPDATE_DECLINE_FAIL,
    READ_DECLINE_REQUEST,
    READ_DECLINE_SUCCESS,
    READ_DECLINE_FAIL,
    CREATE_CREDIT_REQUEST,
    CREATE_CREDIT_SUCCESS,
    CREATE_CREDIT_FAIL,
    UPDATE_CREDIT_REQUEST,
    UPDATE_CREDIT_SUCCESS,
    UPDATE_CREDIT_FAIL,
    READ_CREDIT_REQUEST,
    READ_CREDIT_SUCCESS,
    READ_CREDIT_FAIL,
    CREATE_REVIEW_REQUEST,
    CREATE_REVIEW_SUCCESS,
    CREATE_REVIEW_FAIL,
    READ_REVIEW_REQUEST,
    READ_REVIEW_SUCCESS,
    READ_REVIEW_FAIL,
    CREATE_DELETE_REQUEST,
    CREATE_DELETE_SUCCESS,
    CREATE_DELETE_FAIL,
    READ_DELETE_REQUEST,
    READ_DELETE_SUCCESS,
    READ_DELETE_FAIL,
    CREATE_RO_REQUEST,
    CREATE_RO_SUCCESS,
    CREATE_RO_FAIL,
    READ_RO_REQUEST,
    READ_RO_SUCCESS,
    READ_RO_FAIL,
    UPDATE_RO_REQUEST,
    UPDATE_RO_SUCCESS,
    UPDATE_RO_FAIL,
    CREATE_CUSTOM_COMMENT_REQUEST,
    CREATE_CUSTOM_COMMENT_SUCCESS,
    CREATE_CUSTOM_COMMENT_FAIL,
    READ_CUSTOM_COMMENT_REQUEST,
    READ_CUSTOM_COMMENT_SUCCESS,
    READ_CUSTOM_COMMENT_FAIL,
    UPDATE_CUSTOM_COMMENT_REQUEST,
    UPDATE_CUSTOM_COMMENT_SUCCESS,
    UPDATE_CUSTOM_COMMENT_FAIL,
    DELETE_CUSTOM_COMMENT_REQUEST,
    DELETE_CUSTOM_COMMENT_SUCCESS,
    DELETE_CUSTOM_COMMENT_FAIL
} from "./constants"


const JobsReducer = {

    job: {
        jobs: [],
        normalizedJobs: [],
        newJob: null,
        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: "",

        updating: false,
        updated: false,
        updateError: "",
    },

    ro: {
        ros: [],
        new: undefined,
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
    },

    custom_comments: {
        comments: [],
        new: undefined,
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
    },

    credit: {
        credits: [],

        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: "",

        updating: false,
        updated: false,
        updateError: "",
    },
    timeline: {
        timelines: [],

        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: "",
    },

    otherJob: {
        otherJobs: [],
        newJob: null,
        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: "",

        updating: false,
        updated: false,
        updateError: "",
    },

    pause: {
        pauses: [],

        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: "",

        updating: false,
        updated: false,
        updateError: "",
    },

    image: {
        images: [],

        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: ""
    },

    comment: {
        comments: [],

        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: ""
    },

    review: {
        reviews: [],
        normalizedReviews: [],

        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: ""
    },

    deleted: {
        deletes: [],

        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: ""
    },

    cancel: {
        cancelled: [],

        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: "",

        updating: false,
        updated: false,
        updateError: ""
    },

    decline: {
        declined: [],

        created: false,
        creating: false,
        createError: "",

        loading: false,
        loaded: false,
        loadError: "",

        updating: false,
        updated: false,
        updateError: ""
    }
}

function isIterable(obj) {
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}

const JobReducer = (state = JobsReducer, action) => {
    let newState = Object.assign({}, state)
    let filterJobs, filterOther, filterPauses, filterCancelled
    switch (action.type) {
        case CREATE_CUSTOM_COMMENT_REQUEST:
            return {
                ...newState,
                custom_comments: {
                    ...newState.custom_comments,
                    createStatus: "pending",
                    createMessage: "Creating a new comment... Please wait.",
                }
            }
        case CREATE_CUSTOM_COMMENT_SUCCESS:
            return {
                ...newState,
                custom_comments: {
                    ...newState.custom_comments,
                    createStatus: "success",
                    createMessage: "Comment type created successfully.",
                    comments: [...newState.custom_comments.comments, action.payload._custom_comment]
                }
            }
        case CREATE_CUSTOM_COMMENT_FAIL:
            return {
                ...newState,
                custom_comments: {
                    ...newState.custom_comments,
                    createStatus: "error",
                    createMessage: "Error creating comment. Please try again or contact support.",
                }
            }
        case READ_CUSTOM_COMMENT_REQUEST:
            return {
                ...newState,
                custom_comments: {
                    ...newState.custom_comments,
                    readStatus: "pending",
                    readMessage: "Loading comment types... Please wait.",
                }
            }
        case READ_CUSTOM_COMMENT_SUCCESS:
            return {
                ...newState,
                custom_comments: {
                    ...newState.custom_comments,
                    readStatus: "success",
                    readMessage: "Comment types loaded successfully",
                    comments: action.payload._custom_comments
                }
            }
        case READ_CUSTOM_COMMENT_FAIL:
            return {
                ...newState,
                custom_comments: {
                    ...newState.custom_comments,
                    readStatus: "error",
                    readMessage: "Error loading comments. Please reload the page or contact support.",
                }
            }
        case UPDATE_CUSTOM_COMMENT_REQUEST:
            return {
                ...newState,
                custom_comments: {
                    ...newState.custom_comments,
                    updateStatus: "pending",
                    updateMessage: "Updating comment... Please wait.",
                }
            }
        case UPDATE_CUSTOM_COMMENT_SUCCESS:
            return {
                ...newState,
                custom_comments: {
                    ...newState.custom_comments,
                    updateStatus: "success",
                    updateMessage: "Comment updated successfully.",
                    comments: [...newState.custom_comments.comments.filter(c => c._id !== action.payload._custom_comment._id), action.payload._custom_comment]
                }
            }
        case UPDATE_CUSTOM_COMMENT_FAIL:
            return {
                ...newState,
                custom_comments: {
                    ...newState.custom_comments,
                    updateStatus: "error",
                    updateMessage: "Error updating comment type. Plese try again or contact support.",
                }
            }
        case DELETE_CUSTOM_COMMENT_REQUEST:
            return {
                ...newState,
                custom_comments: {
                    ...newState.custom_comments,
                    deleteStatus: "pending",
                    deleteMessage: "Deleting comment type... Please wait.",
                }
            }
        case DELETE_CUSTOM_COMMENT_SUCCESS:
            return {
                ...newState,
                custom_comments: {
                    ...newState.custom_comments,
                    deleteStatus: "success",
                    deleteMessage: "Comment deleted successfully.",
                    comments: [...newState.custom_comments.comments.filter(c => c._id !== action.payload._id)]
                }
            }
        case DELETE_CUSTOM_COMMENT_FAIL:
            return {
                ...newState,
                custom_comments: {
                    ...newState.custom_comments,
                    deleteStatus: "error",
                    deleteMessage: "Error deleting comment. Please try again or contact support.",
                }
            }
        case CREATE_RO_REQUEST:
            return {
                ...newState,
                ro: {
                    ...newState.ro,
                    createStatus: "pending",
                    createMessage: "Creating RO group... Please wait."
                }
            }
        case CREATE_RO_SUCCESS:
            return {
                ...newState,
                ro: {
                    ...newState.ro,
                    createStatus: "success",
                    createMessage: "RO Group created.",
                    ros: [...newState.ro.ros, action.payload.ro_job],
                    new: action.payload.ro_job
                }
            }
        case CREATE_RO_FAIL:
            return {
                ...newState,
                ro: {
                    ...newState.ro,
                    createStatus: "error",
                    createMessage: "Error creating RO group."
                }
            }
        case READ_RO_REQUEST:
            return {
                ...newState,
                ro: {
                    ...newState.ro,
                    readStatus: "pending",
                    readMessage: "Loading RO job groups... Please wait."
                }
            }
        case READ_RO_SUCCESS:
            return {
                ...newState,
                ro: {
                    ...newState.ro,
                    readStatus: "success",
                    readMessage: "RO Job groups have been loaded.",
                    ros: action.payload.ro_jobs
                }
            }
        case READ_RO_FAIL:
            return {
                ...newState,
                ro: {
                    ...newState.ro,
                    readStatus: "error",
                    readMessage: "Error loading RO jobs."
                }
            }
        case UPDATE_RO_REQUEST:
            return {
                ...newState,
                ro: {
                    ...newState.ro,
                    updateStatus: "pending",
                    updateMessage: "Updatig RO job group. Please wait."
                }
            }
        case UPDATE_RO_SUCCESS:
            return {
                ...newState,
                ro: {
                    ...newState.ro,
                    updateStatus: "success",
                    updateMessage: "RO job group updated successfully.",
                    ros: [...newState.ro.ros.filter(r => r._id !== action.payload.ro_job._id), action.payload.ro_job]
                }
            }
        case UPDATE_RO_FAIL:
            return {
                ...newState,
                ro: {
                    ...newState.ro,
                    updateStatus: "error",
                    updateMessage: "Error updating RO job group. "
                }
            }
        case GET_JOB_REQUEST:
            return newState
        case GET_JOB_SUCCESS:
            return {
                ...newState,
                job: {
                    ...newState.job,
                    jobs: newState.job?.jobs?.length ? [...newState.job.jobs.filter(j => j._id !== action.payload.job._id), action.payload.job] : [action.payload.job]
                },
                image: {
                    ...newState.image,
                    images: newState.image?.images?.length ? [...newState.image.images.filter(i => i._job_id !== action.payload.job._id), ...action.payload.images] : action.payload.images
                },
                comment: {
                    ...newState.comment,
                    comments: newState.comment?.comments?.length ? [...newState.comment.comments.filter(c => c._job_id !== action.payload.job._id), ...action.payload.comments] : action.payload.comments
                },
                pause: {
                    ...newState.pause,
                    pauses: newState.pause?.pauses?.length ? [...newState.pause.pauses.filter(p => p._job_id !== action.payload.job._id), ...action.payload.pauses] : action.payload.pauses
                },
                timeline: {
                    ...newState.timeline,
                    timelines: newState.timeline?.timelines?.length ? [...newState.timeline.timelines.filter(t => t._job_id !== action.payload.job._id), ...action.payload.timelines] : action.payload.timelines
                },
                credit: {
                    ...newState.credit,
                    credit: newState.credit?.credits?.length ? [...newState.credit.credits.filter(c => c._job_id !== action.payload.job._id), ...action.payload.credits] : action.payload.credits
                },
                decline: {
                    ...newState.decline,
                    decline: newState.decline?.declined?.length ? [...newState.decline.declined.filter(d => d._job_id !== action.payload.job._id), ...action.payload.declines] : action.payload.declines
                },
                cancel: {
                    ...newState.cancel,
                    cancelled: newState.cancel?.cancelled?.length ? [...newState.cancel.cancelled.filter(c => c._job_id !== action.payload.job._id), ...action.payload.cancels] : action.payload.cancels
                },
                review: {
                    ...newState.review,
                    review: newState.review?.reviews?.length ? [...newState.review.reviews.filter(r => r._job_id !== action.payload.job._id), ...action.payload.reviews] : action.payload.reviews
                }
            }
        case GET_JOB_FAIL:
            return newState
        case CREATE_JOB_REQUEST:
            newState = {
                ...newState,
                job: {
                    ...newState.job,
                    creating: true,
                    created: false,
                    createError: ""
                }
            }
            return newState
        case CREATE_JOB_SUCCESS:
            newState = {
                ...newState,
                job: {
                    ...newState.job,
                    creating: false,
                    created: true,
                    createError: "",
                    jobs: newState.job.jobs !== null ? [...newState.job.jobs.filter(j => j._id !== action.payload.job._id), action.payload.job] : [action.payload.job],
                    newJob: action.payload.job
                }
            }
            return newState
        case CREATE_JOB_FAIL:
            newState = {
                ...newState,
                job: {
                    ...newState.job,
                    creating: false,
                    created: false,
                    createError: action.payload.message
                }
            }
            return newState
        case READ_JOB_REQUEST:
            newState = {
                ...newState,
                job: {
                    ...newState.job,
                    loading: true,
                    loaded: false,
                    loadError: ""
                }
            }
            return newState
        case READ_JOB_SUCCESS:
            const normalizedJobs = [...state.job.normalizedJobs]

            for (const job of action.payload.jobs) {
                normalizedJobs[job._id] = job
            }
            let setJobs = [...action.payload.jobs]
            if (state.job.jobs?.length !== undefined) {
                for (const job of state.job.jobs) {
                    if (normalizedJobs[job._id] === undefined) normalizedJobs[job._id] = job
                    if (setJobs.find(j => j._id === job._id) === undefined) setJobs.push(job)
                }
            }

            newState = {
                ...newState,
                job: {
                    ...newState.job,
                    loading: false,
                    loaded: true,
                    loadError: "",
                    jobs: setJobs,
                    normalizedJobs: normalizedJobs
                }
            }
            return newState
        case READ_JOB_FAIL:
            newState = {
                ...newState,
                job: {
                    ...newState.job,
                    loading: false,
                    loaded: false,
                    loadError: action.payload.message
                }
            }
            return newState
        case UPDATE_JOB_REQUEST:
            newState = {
                ...newState,
                job: {
                    ...newState.job,
                    updating: true,
                    updated: false,
                    updateError: "",
                },
                timeline: {
                    ...newState.timeline,
                    updating: true,
                    updated: false,
                    updateError: "",
                }
            }
            return newState
        case UPDATE_JOB_SUCCESS:
            filterJobs = newState.job.jobs !== null ? newState.job.jobs.filter(j => parseInt(j._id) !== parseInt(action.payload.job._id)) : []
            newState = {
                ...newState,
                job: {
                    ...newState.job,
                    updating: false,
                    updated: true,
                    updateError: "",
                    jobs: [...filterJobs, action.payload.job]
                },
                timeline: {
                    ...newState.timeline,
                    updating: false,
                    updated: true,
                    updateError: "",
                    timelines: [...newState.timeline.timelines, action.payload.timeline]
                }
            }
            return newState
        case UPDATE_JOB_FAIL:
            newState = {
                ...newState,
                job: {
                    ...newState.job,
                    updating: false,
                    updated: false,
                    updateError: action.payload.message
                },
                timeline: {
                    ...newState.timeline,
                    updating: false,
                    updated: false,
                    updateError: action.payload.message
                }
            }
            return newState

        case READ_JOB_HIST_REQUEST:
            newState = {
                ...newState,
                timeline: {
                    ...newState.timeline,
                    loading: true,
                    loaded: false,
                    loadError: ""
                }
            }
            return newState
        case READ_JOB_HIST_SUCCESS:
            newState = {
                ...newState,
                timeline: {
                    ...newState.timeline,
                    loading: false,
                    loaded: true,
                    loadError: "",
                    timelines: action.payload.timelines
                }
            }
            return newState
        case READ_JOB_HIST_FAIL:
            newState = {
                ...newState,
                timeline: {
                    ...newState.timeline,
                    loading: false,
                    loaded: false,
                    loadError: action.payload.message,
                }
            }
            return newState
        case CREATE_OTHER_REQUEST:
            newState = {
                ...newState,
                otherJob: {
                    ...newState.otherJob,
                    creating: true,
                    created: false,
                    createError: ""
                },
                job: {
                    ...newState.job,
                    creating: true,
                    created: false,
                    createError: ""
                }
            }
            return newState
        case CREATE_OTHER_SUCCESS:
            newState = {
                ...newState,
                otherJob: {
                    ...newState.otherJob,
                    newJob: action.payload.job,
                    creating: false,
                    created: true,
                    createError: "",
                    otherJobs: newState.otherJob.otherJobs !== null ? [...newState.otherJob.otherJobs, action.payload.job] : [action.payload.job]
                },
                job: {
                    ...newState.job,
                    newJob: action.payload.job,
                    creating: false,
                    created: true,
                    createError: "",
                    jobs: newState.job.jobs !== null ? [...newState.job.jobs, action.payload.job] : [action.payload.job]
                }
            }
            return newState
        case CREATE_OTHER_FAIL:
            newState = {
                ...newState,
                otherJob: {
                    ...newState.otherJob,
                    creating: false,
                    created: false,
                    createError: action.payload.message
                },
                job: {
                    ...newState.job,
                    creating: false,
                    created: false,
                    createError: action.payload.message
                }
            }
            return newState
        case READ_OTHER_REQUEST:
            newState = {
                ...newState,
                otherJob: {
                    ...newState.otherJob,
                    loading: true,
                    loaded: false,
                    loadError: ""
                }
            }
            return newState
        case READ_OTHER_SUCCESS:
            newState = {
                ...newState,
                otherJob: {
                    ...newState.otherJob,
                    loading: false,
                    loaded: true,
                    loadError: "",
                    otherJobs: action.payload.jobs
                }
            }
            return newState
        case READ_OTHER_FAIL:
            newState = {
                ...newState,
                otherJob: {
                    ...newState.otherJob,
                    loading: false,
                    loaded: false,
                    loadError: action.payload.message
                }
            }
            return newState
        case UPDATE_OTHER_REQUEST:
            newState = {
                ...newState,
                otherJob: {
                    ...newState.otherJob,
                    updating: true,
                    updated: false,
                    updateError: ""
                }
            }
            return newState
        case UPDATE_OTHER_SUCCESS:
            filterOther = newState.otherJob.otherJobs !== null ? newState.otherJob.otherJobs.filter(j => parseInt(j._id) !== parseInt(action.payload.job._id)) : []
            newState = {
                ...newState,
                otherJob: {
                    ...newState.otherJob,
                    updating: false,
                    updated: true,
                    updateError: "",
                    otherJobs: [...filterOther, action.payload.job]
                }
            }
            return newState
        case UPDATE_OTHER_FAIL:
            newState = {
                ...newState,
                otherJob: {
                    ...newState.otherJob,
                    updating: false,
                    updated: false,
                    updateError: action.payload.message
                }
            }
            return newState
        case CREATE_PAUSE_REQUEST:
            newState = {
                ...newState,
                pause: {
                    ...newState.pause,
                    creating: true,
                    created: false,
                    createError: ""
                }
            }
            return newState
        case CREATE_PAUSE_SUCCESS:
            newState = {
                ...newState,
                pause: {
                    ...newState.pause,
                    creating: false,
                    created: true,
                    createError: "",
                    pauses: newState.pause.pauses !== null ? [...newState.pause.pauses, action.payload.pause] : [action.payload.pause]
                }
            }
            return newState
        case CREATE_PAUSE_FAIL:
            newState = {
                ...newState,
                pause: {
                    ...newState.pause,
                    creating: false,
                    created: false,
                    createError: action.payload.message
                }
            }
            return newState
        case READ_PAUSE_REQUEST:
            newState = {
                ...newState,
                pause: {
                    ...newState.pause,
                    loading: true,
                    loaded: false,
                    loadError: ""
                }
            }
            return newState
        case READ_PAUSE_SUCCESS:
            newState = {
                ...newState,
                pause: {
                    ...newState.pause,
                    loading: false,
                    loaded: true,
                    loadError: "",
                    pauses: action.payload.pauses
                }
            }
            return newState
        case READ_PAUSE_FAIL:
            newState = {
                ...newState,
                pause: {
                    ...newState.pause,
                    loading: false,
                    loaded: false,
                    loadError: action.payload.message
                }
            }
            return newState
        case UPDATE_PAUSE_REQUEST:
            newState = {
                ...newState,
                pause: {
                    ...newState.pause,
                    updating: true,
                    updated: false,
                    updateError: ""
                }
            }
            return newState
        case UPDATE_PAUSE_SUCCESS:
            filterPauses = newState.pause.pauses !== null ? newState.pause.pauses.filter(j => parseInt(j._id) !== parseInt(action.payload.pause._id)) : []
            newState = {
                ...newState,
                pause: {
                    ...newState.pause,
                    updating: false,
                    updated: true,
                    updateError: "",
                    pauses: [...filterPauses, action.payload.pause]
                }
            }
            return newState
        case UPDATE_PAUSE_FAIL:
            newState = {
                ...newState,
                pause: {
                    ...newState.pause,
                    updating: false,
                    updated: false,
                    updateError: action.payload.message
                }
            }
            return newState
        case CREATE_IMAGE_REQUEST:
            newState = {
                ...newState,
                image: {
                    ...newState.image,
                    creating: true,
                    created: false,
                    createError: ""
                }
            }
            return newState
        case CREATE_IMAGE_SUCCESS:
            newState = {
                ...newState,
                image: {
                    ...newState.image,
                    creating: false,
                    created: true,
                    createError: "",
                    images: newState.image.images !== null ? [...newState.image.images.filter(i => i._job_id !== action.payload.images[0]._job_id), ...action.payload.images] : [...action.payload.images]
                }
            }
            return newState
        case CREATE_IMAGE_FAIL:
            newState = {
                ...newState,
                image: {
                    ...newState.image,
                    creating: false,
                    created: false,
                    createError: action.payload.message
                }
            }
            return newState
        case READ_IMAGE_REQUEST:
            newState = {
                ...newState,
                image: {
                    ...newState.image,
                    loading: true,
                    loaded: false,
                    loadError: ""
                }
            }
            return newState
        case READ_IMAGE_SUCCESS:
            newState = {
                ...newState,
                image: {
                    ...newState.image,
                    loading: false,
                    loaded: true,
                    loadError: "",
                    images: action.payload.images
                }
            }
            return newState
        case READ_IMAGE_FAIL:
            newState = {
                ...newState,
                image: {
                    ...newState.image,
                    loading: false,
                    loaded: false,
                    loadError: action.payload.message
                }
            }
            return newState
        case CREATE_COMMENT_REQUEST:
            newState = {
                ...newState,
                comment: {
                    ...newState.comment,
                    creating: true,
                    created: false,
                    createError: ""
                }
            }
            return newState
        case CREATE_COMMENT_SUCCESS:
            newState = {
                ...newState,
                comment: {
                    ...newState.comment,
                    creating: false,
                    created: true,
                    createError: "",
                    comments: newState.comment.comments !== null ? [...newState.comment.comments, action.payload.comment] : [action.payload.comment]
                }
            }
            return newState
        case CREATE_COMMENT_FAIL:
            newState = {
                ...newState,
                comment: {
                    ...newState.comment,
                    creating: false,
                    created: false,
                    createError: action.payload.message
                }
            }
            return newState
        case READ_COMMENT_REQUEST:
            newState = {
                ...newState,
                comment: {
                    ...newState.comment,
                    loading: true,
                    loaded: false,
                    loadError: ""
                }
            }
            return newState
        case READ_COMMENT_SUCCESS:
            newState = {
                ...newState,
                comment: {
                    ...newState.comment,
                    loading: false,
                    loaded: true,
                    loadError: "",
                    comments: action.payload.comments
                }
            }
            return newState
        case READ_COMMENT_FAIL:
            newState = {
                ...newState,
                comment: {
                    ...newState.comment,
                    loading: false,
                    loaded: false,
                    loadError: action.payload.message
                }
            }
            return newState
        case CREATE_CANCEL_REQUEST:
            newState = {
                ...newState,
                cancel: {
                    ...newState.cancel,
                    creating: true,
                    created: false,
                    createError: ""
                }
            }
            return newState
        case CREATE_CANCEL_SUCCESS:
            newState = {
                ...newState,
                cancel: {
                    ...newState.cancel,
                    creating: false,
                    created: true,
                    createError: "",
                    cancelled: newState.cancel.cancelled === undefined ? [action.payload.cancel] : [...newState.cancel.cancelled, action.payload.cancel]
                }
            }
            return newState
        case CREATE_CANCEL_FAIL:
            newState = {
                ...newState,
                cancel: {
                    ...newState.cancel,
                    creating: false,
                    created: false,
                    createError: action.payload.message
                }
            }
            return newState
        case READ_CANCEL_REQUEST:
            newState = {
                ...newState,
                cancel: {
                    ...newState.cancel,
                    loading: true,
                    loaded: false,
                    loadError: ""
                }
            }
            return newState
        case READ_CANCEL_SUCCESS:
            newState = {
                ...newState,
                cancel: {
                    ...newState.cancel,
                    loading: false,
                    loaded: true,
                    loadError: "",
                    createError: "",
                    cancelled: action.payload.cancels
                }
            }
            return newState
        case READ_CANCEL_FAIL:
            newState = {
                ...newState,
                cancel: {
                    ...newState.cancel,
                    loading: false,
                    loaded: false,
                    loadError: action.payload.message
                }
            }
            return newState
        case UPDATE_CANCEL_REQUEST:
            newState = {
                ...newState,
                cancel: {
                    ...newState.cancel,
                    updating: true,
                    updated: false,
                    updateError: ""
                }
            }
            return newState
        case UPDATE_CANCEL_SUCCESS:
            filterCancelled = newState.cancel.cancelled !== null ? newState.cancel.cancelled.filter(c => parseInt(c._id) !== parseInt(action.payload.cancel._id)) : []
            newState = {
                ...newState,
                cancel: {
                    ...newState.cancel,
                    updating: false,
                    updated: true,
                    updateError: "",
                    cancelled: [...filterCancelled, action.payload.cancel]
                }
            }
            return newState
        case UPDATE_CANCEL_FAIL:
            newState = {
                ...newState,
                cancel: {
                    ...newState.cancel,
                    updating: false,
                    updated: false,
                    updateError: action.payload.message
                }
            }
            return newState
        case CREATE_DECLINE_REQUEST:
            newState = {
                ...newState,
                decline: {
                    ...newState.decline,
                    creating: true,
                    created: false,
                    createError: ""
                }
            }
            return newState
        case CREATE_DECLINE_SUCCESS:
            newState = {
                ...newState,
                decline: {
                    ...newState.decline,
                    creating: false,
                    created: true,
                    createError: "",
                    declined: newState.decline.declined !== null ? [...newState.decline.declined, action.payload.decline] : [action.payload.decline]
                    // declined : newState.decline.declined === undefined ? [action.payload.decline] : [...newState.decline.declined, action.payload.decline]
                }
            }
            return newState
        case CREATE_DECLINE_FAIL:
            newState = {
                ...newState,
                decline: {
                    ...newState.decline,
                    creating: false,
                    created: false,
                    createError: action.payload.message
                }
            }
            return newState
        case UPDATE_DECLINE_REQUEST:
            newState = {
                ...newState,
                decline: {
                    ...newState.decline,
                    updating: true,
                    updated: false,
                    updateError: ""
                }
            }
            return newState
        case UPDATE_DECLINE_SUCCESS:
            newState = {
                ...newState,
                decline: {
                    ...newState.decline,
                    updating: false,
                    updated: true,
                    updateError: "",
                    declined: newState.decline.declined !== null ? [...newState.decline.declined.filter(decline => decline._id !== action.payload.decline._id), action.payload.decline] : [action.payload.decline]
                },
                job: {
                    ...newState.job,
                    jobs: newState.job.jobs !== null ? [...newState.job.jobs.filter(job => job._id !== action.payload.job._id), action.payload.job] : [action.payload.job]
                }
            }
            return newState
        case UPDATE_DECLINE_FAIL:
            newState = {
                ...newState,
                decline: {
                    ...newState.decline,
                    updating: false,
                    updated: false,
                    updateError: action.payload.message
                }
            }
            return newState
        case READ_DECLINE_REQUEST:
            newState = {
                ...newState,
                decline: {
                    ...newState.decline,
                    loading: true,
                    loaded: false,
                    loadError: ""
                }
            }
            return newState
        case READ_DECLINE_SUCCESS:
            newState = {
                ...newState,
                decline: {
                    ...newState.decline,
                    loading: false,
                    loaded: true,
                    loadError: "",
                    declined: action.payload.declines
                }
            }
            return newState
        case READ_DECLINE_FAIL:
            newState = {
                ...newState,
                decline: {
                    ...newState.decline,
                    loading: false,
                    loaded: false,
                    loadError: action.payload.message
                }
            }
            return newState
        case CREATE_CREDIT_REQUEST:
            newState = {
                ...newState,
                credit: {
                    ...newState.credit,
                    creating: true,
                    created: false,
                    createError: ""
                }
            }
            return newState
        case CREATE_CREDIT_SUCCESS:
            const newCredits = isIterable(newState.credit.credits) === true ? [...newState.credit.credits] : []
            const newImages = isIterable(newState.image.images) === true ? [...newState.image.images] : []
            newState = {
                ...newState,
                credit: {
                    ...newState.credit,
                    creating: false,
                    created: true,
                    createError: "",
                    credits: [...newCredits, action.payload.credit]
                },
                image: {
                    ...newState.image,
                    images: [...newImages, action.payload.credit_image]
                }
            }
            return newState
        case CREATE_CREDIT_FAIL:
            newState = {
                ...newState,
                credit: {
                    ...newState.credit,
                    creating: false,
                    created: false,
                    createError: action.payload.message
                }
            }
            return newState
        case UPDATE_CREDIT_REQUEST:
            newState = {
                ...newState,
                credit: {
                    ...newState.credit,
                    updating: true,
                    updated: false,
                    updateError: ""
                }
            }
            return newState
        case UPDATE_CREDIT_SUCCESS:
            newState = {
                ...newState,
                credit: {
                    ...newState.credit,
                    updating: false,
                    updated: true,
                    updateError: "",
                    credits: action.payload.credits
                }
            }
            return newState
        case UPDATE_CREDIT_FAIL:
            newState = {
                ...newState,
                credit: {
                    ...newState.credit,
                    updating: false,
                    updated: false,
                    updateError: action.payload.message
                }
            }
            return newState
        case READ_CREDIT_REQUEST:
            newState = {
                ...newState,
                credit: {
                    ...newState.credit,
                    loading: true,
                    loaded: false,
                    loadError: ""
                }
            }
            return newState
        case READ_CREDIT_SUCCESS:
            newState = {
                ...newState,
                credit: {
                    ...newState.credit,
                    loading: false,
                    loaded: true,
                    loadError: "",
                    credits: action.payload.credits
                }
            }
            return newState
        case READ_CREDIT_FAIL:
            newState = {
                ...newState,
                credit: {
                    ...newState.credit,
                    loading: false,
                    loaded: false,
                    loadError: action.payload.message
                }
            }
            return newState
        case CREATE_REVIEW_REQUEST:
            newState = {
                ...newState,
                review: {
                    ...newState.review,
                    creating: true,
                    created: false,
                    createError: ""
                }
            }
            return newState
        case CREATE_REVIEW_SUCCESS:
            const newReviews = isIterable(newState.review.reviews) === true ? [...newState.review.reviews] : []
            const newNormalizedReiveiws = isIterable(newState.review.normalizedReviews) === true ? [...newState.review.normalizedReviews] : []
            let _normalizedReviews = [...newNormalizedReiveiws]
            if (action.payload?.review !== undefined) _normalizedReviews[action.payload.review._job_id] = action.payload.review
            if (action.payload?.reviews !== undefined) action.payload.reviews.map(rev => { _normalizedReviews[rev._job_id] = rev; return true })
            newState = {
                ...newState,
                review: {
                    ...newState.review,
                    creating: false,
                    created: true,
                    createError: "",
                    reviews: action.payload?.review !== undefined ? [...newReviews, action.payload.review] : action.payload?.reviews !== undefined ? [...newReviews, ...action.payload.reviews] : newReviews,
                    normalizedReviews: _normalizedReviews
                }
            }
            return newState
        case CREATE_REVIEW_FAIL:
            newState = {
                ...newState,
                review: {
                    ...newState.review,
                    creating: false,
                    created: false,
                    createError: action.payload.message
                }
            }
            return newState
        case READ_REVIEW_REQUEST:
            newState = {
                ...newState,
                review: {
                    ...newState.review,
                    loading: true,
                    loaded: false,
                    loadError: ""
                }
            }
            return newState
        case READ_REVIEW_SUCCESS:
            const __normalizedReviews = []

            for (const rev of action.payload.reviews) {
                __normalizedReviews[rev._job_id] = rev
            }

            newState = {
                ...newState,
                review: {
                    ...newState.review,
                    loading: false,
                    loaded: true,
                    loadError: "",
                    reviews: action.payload.reviews,
                    normalizedReviews: __normalizedReviews
                }
            }
            return newState
        case READ_REVIEW_FAIL:
            newState = {
                ...newState,
                review: {
                    ...newState.review,
                    loading: false,
                    loaded: false,
                    loadError: action.payload.message
                }
            }
            return newState
        // case SEE_REVIEW_REQUEST:
        //     newState = {
        //         ...newState,
        //         review: {
        //             ...newState.review,
        //         }
        //     }
        // case SEE_REVIEW_SUCCESS:
        //     newState = {
        //         ...newState,
        //         review: {
        //             ...newState.review,
        //             reviews: [...newState.review.reviews.filter(rev => rev._id !== action.payload._id), {...newState.review.reviews.find(rev => rev._id === action.payload._id), _seen: 1}]
        //         }
        //     }
        // case SEE_REVIEW_FAIL:
        //     newState = {
        //         ...newState,
        //         review: {
        //             ...newState.review,
        //         }
        //     }
        case CREATE_DELETE_REQUEST:
            newState = {
                ...newState,
                deleted: {
                    ...newState.deleted,
                    creating: true,
                    created: false,
                    createError: ""
                }
            }
            return newState
        case CREATE_DELETE_SUCCESS:
            const newDeletes = isIterable(newState.deleted.deletes) === true ? [...newState.deleted.deletes] : []
            const newJobs = isIterable(newState.job.jobs) === true ? [...newState.job.jobs] : []
            newState = {
                ...newState,
                deleted: {
                    ...newState.deleted,
                    creating: false,
                    created: true,
                    createError: "",
                    deletes: [...newDeletes, action.payload.deletedJob]
                },
                job: {
                    ...newState.job,
                    jobs: [
                        ...newJobs.filter(job => compareInt(job._id, action.payload.deletedJob._job_id) === false),
                        action.payload.newJob
                    ]
                }
            }
            return newState
        case CREATE_DELETE_FAIL:
            newState = {
                ...newState,
                deleted: {
                    ...newState.deleted,
                    creating: false,
                    created: false,
                    createError: action.payload.message
                }
            }
            return newState
        case READ_DELETE_REQUEST:
            newState = {
                ...newState,
                deleted: {
                    ...newState.deleted,
                    loading: true,
                    loaded: false,
                    loadError: "",
                }
            }
            return newState
        case READ_DELETE_SUCCESS:
            newState = {
                ...newState,
                deleted: {
                    ...newState.deleted,
                    loading: false,
                    loaded: true,
                    loadError: "",
                    deletes: action.payload.deletedJobs
                }
            }
            return newState
        case READ_DELETE_FAIL:
            newState = {
                ...newState,
                deleted: {
                    ...newState.deleted,
                    loading: false,
                    loaded: false,
                    loadError: action.payload.message
                }
            }
            return newState
        default:
            return { ...state }
    }
}

export default JobReducer